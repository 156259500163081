import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import FilterIcon from '@mui/icons-material/Filter';
import DowlnoadIcon from '@mui/icons-material/Download';
import { CardMedia, Divider, IconButton, MenuItem, Tooltip } from '@mui/material';
import { Formik } from 'formik';
import { find } from 'lodash';
import ImagePlaceholder from 'icons/NA.jpg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckBoxField from '../../common/CheckBoxField';
import DeleteEntryModal from '../../common/DeleteEntryModal';
import ImageViewerModal from '../../common/ImageViewerModal';
import InputField from '../../common/InputField';
import { ListenChanges } from '../../common/ListenChanges/ListenChanges';
import SelectField from '../../common/SelectField';
import SimpleField from '../../common/SimpleField';
import { Spinner } from '../../common/Spinner/Spinner';
import useModal from '../../shared/hooks/useModal/useModal';
import styles from './Products.module.scss';
import {
  deleteProductRequest,
  getPhotoRequest,
  getPhotoSuccess,
  getProductsByGtinRequest,
  getProductsByGtinSuccess,
  postPrintProductProfileRequest,
  putUpdateProductRequest,
  putUpdateProductSuccess,
} from './services/actions';
import { productSelector } from './services/selector';
import { ProductsSchema } from './services/validation';
import { ExitPageModal } from '../../common/ExitPageModal/ExitPageModal';
import ErrorResponse from '../../shared/ErrorResponse';
import { format } from 'date-fns';
import { ProductImageModal } from '../../common/ProductImageModal/ProductImageModal';
import { productInit } from './constants';

export const Products: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const gtinFromQuery = location.search.slice(1);

  const {
    product,
    photo,
    mainPhoto,
    loading,
    deleteProductErrorResponses,
    updateProductSuccessResponse,
    updateProductErrorResponses,
    getPhotoErrorResponses,
    getProductsErrorResponses,
    getProductByGtinErrorResponses,
    createProductErrorResponses,
  } = useSelector(productSelector);

  const [initialValue, setInitialValue] = useState<any>(productInit);

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const { isShow: isShownViewer, openModal: openViewerModal, closeModal: closeViewerModal } = useModal();
  const { isShow: isShowImageModal, openModal: openImageModal, closeModal: closeImageModal } = useModal();
  const { isShow: isShownDelete, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();
  const { isShow: isShowExit, openModal: openExitModal, closeModal: closeExitModal } = useModal();

  const { t } = useTranslation();

  const onSaveProductHandler = (values: any) => {
    dispatch(putUpdateProductRequest({ ...values }));
  };

  const onOpenViewerModal = (template: any) => {
    if (template?.photos.length !== 0) {
      dispatch(getPhotoRequest(template?.photos[0]?.url));
    }
    openViewerModal();
  };

  const onOpenImageModal = () => {
    // dispatch(getPhotoRequest(product.imagePath));
    openImageModal();
  };

  const onCloseViewerModal = () => {
    dispatch(getPhotoSuccess(null));
    closeViewerModal();
  };

  const onCloseImageModal = () => {
    closeImageModal();
  };

  const exitProductPage = () => {
    dispatch(getProductsByGtinSuccess({}));
    navigate('/products');
  };

  useEffect(() => {
    dispatch(getProductsByGtinRequest({ gtin: gtinFromQuery }));
  }, [dispatch, gtinFromQuery]);

  useEffect(() => {
    setInitialValue({ ...product });
  }, [product]);

  useEffect(() => {
    if (updateProductSuccessResponse) {
      const timer = setTimeout(() => {
        dispatch(putUpdateProductSuccess({}));
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [updateProductSuccessResponse, dispatch]);

  return (
    <div className={styles.wrapper}>
      {Object.keys(deleteProductErrorResponses).length !== 0 ? (
        <ErrorResponse error={deleteProductErrorResponses} />
      ) : null}
      {Object.keys(getProductsErrorResponses).length !== 0 ? <ErrorResponse error={getProductsErrorResponses} /> : null}
      {Object.keys(getProductByGtinErrorResponses).length !== 0 ? (
        <ErrorResponse error={getProductByGtinErrorResponses} />
      ) : null}
      {Object.keys(createProductErrorResponses).length !== 0 ? (
        <ErrorResponse error={createProductErrorResponses} />
      ) : null}
      {Object.keys(updateProductErrorResponses).length !== 0 ? (
        <ErrorResponse error={updateProductErrorResponses} />
      ) : null}
      {Object.keys(getPhotoErrorResponses).length !== 0 ? <ErrorResponse error={getPhotoErrorResponses} /> : null}

      <Formik
        initialValues={initialValue}
        onSubmit={() => undefined}
        enableReinitialize
        validationSchema={ProductsSchema}
        validateOnChange
        validateOnMount
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <div className={styles.formWrapper}>
            <>
              {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
              {useEffect(() => {
                setFieldValue('imagePath', mainPhoto);
              }, [mainPhoto])}
            </>
            <div className={styles.closeGroup}>
              {hasChanges &&
              values?.internalName !== null &&
              values?.internalName !== '' &&
              values?.weightGR !== null &&
              values?.weightGR !== '' ? (
                <>
                  <span>Натисніть щоб зберегти зміни</span>
                  <SaveAsOutlinedIcon
                    className={styles.icon}
                    onClick={() => {
                      onSaveProductHandler(values);
                    }}
                  />
                </>
              ) : (
                <SaveAsOutlinedIcon className={styles.dicon} />
              )}
              <Tooltip title="Зкачати в форматі pdf" placement="top">
                <DowlnoadIcon
                  className={styles.icon}
                  onClick={() => dispatch(postPrintProductProfileRequest({ productId: values.id, gtin: values.gtin }))}
                />
              </Tooltip>
              <Tooltip title="Видалити продукт з системи" placement="top">
                <DeleteOutlinedIcon
                  className={styles.icon}
                  onClick={() => {
                    openDeleteModal();
                  }}
                />
              </Tooltip>
              <Tooltip title="Закрити" placement="top">
                <CloseIcon
                  className={styles.icon}
                  onClick={() => {
                    hasChanges ? openExitModal() : exitProductPage();
                  }}
                />
              </Tooltip>
            </div>
            <div className={styles.productGroup}>
              <span className={styles.productsTitle}>Профіль продукту {values.gtin}</span>
              {loading ? <Spinner /> : null}
            </div>
            <div className={styles.editorLegend}>
              <span className={styles.editorLegendText}>
                Створено: {product?.creatorEmail},{' '}
                {product.createDate && product.createDate !== null
                  ? format(new Date(product?.createDate), 'yyyy-MM-dd HH:mm:ss')
                  : null}
                {', '}
                востаннє оновленно:{' '}
                {product.updateDate && product.updateDate !== null
                  ? format(new Date(product?.updateDate), 'yyyy-MM-dd HH:mm:ss')
                  : null}
              </span>
            </div>
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.listener}>
                {Object.keys(product).length !== 0 ? (
                  <ListenChanges values={values} initialValues={initialValue} setHasChanges={setHasChanges} />
                ) : null}
              </div>
              {Object.keys(values).length !== 0 ? (
                <section className={styles.productData}>
                  <div className={styles.mainBlock}>
                    <div className={styles.leftBlock}>
                      <InputField
                        title="Внутрішня назва продукції"
                        name="internalName"
                        size="large"
                        value={values?.internalName}
                        maxLength={250}
                        required
                        validate
                        disabled={Object.keys(product).length === 0}
                        extendedOnChange={(event) => {
                          const inputValue = event.target.value;

                          if (inputValue.startsWith(' ')) {
                            event.target.value = inputValue.trim();
                            return;
                          }

                          event.target.value = inputValue.replace(/\s{2,}/g, ' ');

                          setFieldValue('internalName', event.target.value);
                        }}
                      />
                      {values?.externalNames?.map((externalName: any, index: number) => (
                        <InputField
                          title={`Експортна назва ${externalName.countryName}`}
                          name={`externalNames[${index}].name`}
                          size="large"
                          value={values.externalNames[index].name}
                          maxLength={250}
                          disabled={Object.keys(product).length === 0}
                          extendedOnChange={(event) => {
                            const inputValue = event.target.value;

                            if (inputValue.startsWith(' ')) {
                              event.target.value = inputValue.trim();
                              return;
                            }
                            event.target.value = inputValue.replace(/\s{2,}/g, ' ');

                            setFieldValue(`externalNames[${index}].name`, event.target.value);
                          }}
                        />
                      ))}
                      <InputField
                        title="Внутрішній код продукту"
                        name="internalProductCode"
                        value={values?.internalProductCode}
                        size="large"
                        maxLength={50}
                        extendedOnChange={(event) => {
                          const inputValue = event.target.value;

                          if (inputValue.startsWith(' ')) {
                            event.target.value = inputValue.trim();
                            return;
                          }

                          event.target.value = inputValue.replace(/\s{2,}/g, ' ');

                          setFieldValue('internalProductCode', event.target.value);
                        }}
                      />
                      <InputField
                        title="Вага одиниці, гр"
                        name="weightGR"
                        size="large"
                        type="number"
                        maxLength={5}
                        value={values?.weightGR}
                        required
                        validate
                        disabled={Object.keys(product).length === 0}
                      />

                      <div className={styles.error}>
                        {updateProductSuccessResponse?.Result === 0 && updateProductSuccessResponse?.UserText}
                      </div>
                    </div>
                    <div className={styles.rightBlock}>
                      <CardMedia
                        component="img"
                        onClick={onOpenImageModal}
                        image={values?.imagePath || ImagePlaceholder}
                        sx={{ objectFit: 'contain', width: '300px', height: '150px', bgcolor: '#D9D9D9' }}
                      />
                      <Tooltip title="Перегляд і редагування фото продукту" placement="top">
                        <IconButton className={styles.iconButton} onClick={onOpenImageModal} disabled={loading}>
                          <ZoomInIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </section>
              ) : null}
              {Object.keys(product).length !== 0 ? (
                <>
                  <Divider className={styles.divider} />
                  <section className={styles.packingConfig}>
                    <span>
                      1. Оберіть в які типи пакувань (ящиків) може входити даний продукт, для кожного дозволеного
                      оберіть оберіть кількість одиниць і шаблон агрегаційної етикетки.
                    </span>
                    <div className={styles.packingTable}>
                      <div className={styles.firstColumn}>
                        <span className={styles.title}>Задіяно</span>
                        {values?.packingConfigurations?.map((packConfig: any, index: number) => (
                          <CheckBoxField
                            checked={packConfig?.productId !== null}
                            onChange={() => {
                              packConfig?.productId === null
                                ? setFieldValue(`packingConfigurations[${index}].productId`, values.id)
                                : setFieldValue(`packingConfigurations[${index}].productId`, null);
                            }}
                          />
                        ))}
                      </div>
                      <div className={styles.secondColumn}>
                        <span className={styles.title}>Назва пакування</span>
                        {values?.packingConfigurations?.map((packConfig: any, index: number) => (
                          <SimpleField
                            name={`packingConfigurations[${index}].packing.name`}
                            value={packConfig.packing.name}
                            disabled
                          />
                        ))}
                      </div>
                      <div className={styles.thirdColumn}>
                        <span className={styles.title}>Код країни</span>
                        {values?.packingConfigurations?.map((packConfig: any, index: number) => (
                          <SimpleField
                            name={`packingConfigurations[${index}].packing.countryCode`}
                            value={packConfig.packing.countryCode ? packConfig.packing.countryCode : ''}
                            size="small"
                            disabled
                          />
                        ))}
                      </div>
                      <div className={styles.thirdColumn}>
                        <span className={styles.title}>Кількість ярусів</span>
                        {values?.packingConfigurations?.map((packConfig: any, index: number) => (
                          <SimpleField
                            name={`packingConfigurations[${index}].layersCount`}
                            value={packConfig.layersCount ? packConfig.layersCount : ''}
                            size="small"
                            disabled={packConfig.productId === null}
                            validate={packConfig.productId !== null}
                            type="number"
                            maxLength={3}
                          />
                        ))}
                      </div>
                      <div className={styles.fourthColumn}>
                        <span className={styles.title}>Кількість в ярусі</span>
                        {values?.packingConfigurations?.map((packConfig: any, index: number) => (
                          <SimpleField
                            name={`packingConfigurations[${index}].amountPerLayer`}
                            value={packConfig.amountPerLayer ? packConfig.amountPerLayer : ''}
                            size="small"
                            disabled={packConfig.productId === null}
                            validate={packConfig.productId !== null}
                            type="number"
                            maxLength={3}
                          />
                        ))}
                      </div>
                      <div className={styles.fifthColumn}>
                        <span className={styles.title}>Наповнення по кількості</span>
                        {values?.packingConfigurations?.map((packConfig: any, index: number) => (
                          <SelectField
                            title=""
                            name={`packingConfigurations[${index}].strictlyFull`}
                            value={packConfig.strictlyFull}
                            size="small"
                            variant="noLabel"
                            disabled={packConfig.productId === null}
                            error={packConfig.productId !== null && packConfig.strictlyFull === null}
                          >
                            <MenuItem value={true as any} sx={{ fontSize: '13px' }}>
                              завжди повний
                            </MenuItem>
                            <MenuItem value={false as any} sx={{ fontSize: '13px' }}>
                              дозволити неповний
                            </MenuItem>
                          </SelectField>
                        ))}
                      </div>
                      <div className={styles.fifthColumn}>
                        <span className={styles.title}>Шаблон ящика (Level 2)</span>
                        {values?.packingConfigurations?.map((packConfig: any, index: number) => (
                          <SelectField
                            title=""
                            name={`packingConfigurations[${index}].labelTemplateId`}
                            value={packConfig.labelTemplateId}
                            variant="noLabel"
                            disabled={packConfig.productId === null}
                            error={packConfig.productId !== null && packConfig.labelTemplateId === null}
                          >
                            {values?.labelTemplates?.map((template: any) => (
                              <MenuItem value={template.id} sx={{ fontSize: '13px' }}>
                                {template.templateName} ({template.lang})
                              </MenuItem>
                            ))}
                          </SelectField>
                        ))}
                      </div>
                      <div className={styles.sixthColumn}>
                        <span className={styles.title}>Фото</span>
                        {values?.packingConfigurations?.map((v: any) => (
                          <Tooltip title="Вигляд обраного шаблону ящика" placement="top">
                            <FilterIcon
                              className={
                                v?.productId === null || v?.labelTemplateId === null
                                  ? styles.noPhotoIcon
                                  : styles.photoIcon
                              }
                              fontSize="large"
                              onClick={() => {
                                if (
                                  v?.productId !== null &&
                                  v?.labelTemplateId !== null &&
                                  v?.labelTemplate?.photos?.length !== 0
                                ) {
                                  onOpenViewerModal(find(values.labelTemplates, ['id', v.labelTemplateId]));
                                }
                              }}
                            />
                          </Tooltip>
                        ))}
                      </div>
                    </div>
                  </section>
                  <Divider className={styles.divider} />
                  <section className={styles.templatesConfig}>
                    <span>2. Оберіть шаблони, які будуть доступні в програмах маркування для даного продукту.</span>
                    <div className={styles.templatesConfigTable}>
                      <div className={styles.firstColumn}>
                        <span>Задіяно</span>
                        {values?.templateConfigurations?.map((templateConfig: any, index: number) => (
                          <CheckBoxField
                            checked={templateConfig?.productId !== null}
                            onChange={() => {
                              templateConfig?.productId === null
                                ? setFieldValue(`templateConfigurations[${index}].productId`, values.id)
                                : setFieldValue(`templateConfigurations[${index}].productId`, null);
                            }}
                          />
                        ))}
                      </div>
                      <div className={styles.secondColumn}>
                        <span>Шаблон продукції (Level 0)</span>
                        {values?.templateConfigurations?.map((templateConfig: any, index: number) => (
                          <SimpleField
                            name={`templateConfigurations[${index}].labelTemplate.templateName`}
                            value={templateConfig.labelTemplate.templateName}
                            disabled
                          />
                        ))}
                      </div>
                      <div className={styles.secondColumn}>
                        <span>{t('country')}</span>
                        {values?.templateConfigurations?.map((templateConfig: any, index: number) => (
                          <SimpleField
                            name={`templateConfigurations[${index}].labelTemplate.countryCode`}
                            size="small"
                            value={templateConfig.labelTemplate.countryCode}
                            disabled
                          />
                        ))}
                      </div>
                      <div className={styles.secondColumn}>
                        <span>{t('printLang')}</span>
                        {values?.templateConfigurations?.map((templateConfig: any, index: number) => (
                          <SimpleField
                            name={`templateConfigurations[${index}].labelTemplate.lang`}
                            size="small"
                            value={templateConfig.labelTemplate.lang}
                            disabled
                          />
                        ))}
                      </div>
                      <div className={styles.thirdColumn}>
                        <span>Фото</span>
                        {values?.templateConfigurations?.map((templateConfig: any) => (
                          <Tooltip title="Вигляд обраного шаблону маркування" placement="top">
                            <FilterIcon
                              className={
                                templateConfig?.labelTemplate.photos.length === 0
                                  ? styles.noPhotoIcon
                                  : styles.photoIcon
                              }
                              fontSize="large"
                              onClick={() => {
                                if (templateConfig?.labelTemplate.photos.length !== 0) {
                                  onOpenViewerModal(templateConfig?.labelTemplate);
                                }
                              }}
                            />
                          </Tooltip>
                        ))}
                      </div>
                    </div>
                  </section>
                  <Divider className={styles.divider} />
                </>
              ) : null}
            </form>
          </div>
        )}
      </Formik>
      <ImageViewerModal
        isShownViewer={isShownViewer}
        closeViewerModal={onCloseViewerModal}
        extended={false}
        img={photo}
      />
      <ProductImageModal
        isShownViewer={isShowImageModal}
        closeViewerModal={onCloseImageModal}
        productId={product?.id}
        img={product?.imagePath}
      />
      {Object.keys(product).length !== 0 ? (
        <DeleteEntryModal
          title={`Видалити запис з кодом ${product?.gtin} ?`}
          isShow={isShownDelete}
          closeModal={closeDeleteModal}
          deleteHandler={() => {
            dispatch(deleteProductRequest({ productId: product?.id }));
            navigate('/products');
            closeDeleteModal();
          }}
        />
      ) : null}
      {Object.keys(product).length !== 0 ? (
        <ExitPageModal
          title={'Закрити без збереження змін?'}
          isShow={isShowExit}
          closeModal={closeExitModal}
          exitHandler={() => {
            navigate('/products');
            closeExitModal();
          }}
        />
      ) : null}
    </div>
  );
};
