import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InputSearch from '../../common/InputSearch';
import DataTable from '../../common/DataTable';
import useModal from '../../shared/hooks/useModal/useModal';

import styles from './Packaging.module.scss';
import AddPackModal from '../../common/AddPackModal';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPackingsRequest, postAddNewPackingRequest } from './services/actions';
import { packingSelector } from './services/selector';
import { Spinner } from '../../common/Spinner/Spinner';
import ErrorResponse from '../../shared/ErrorResponse';
import { useNavigate } from 'react-router-dom';
import { getCountryListRequest } from '../../../entities/Countries/actions';

export const Packaging: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isShow, openModal, closeModal } = useModal();

  const {
    packages,
    loading,
    getPackingErrorResponses,
    getPackingByIdErrorResponses,
    addPackingErrorResponses,
    updatePackingErrorResponses,
  } = useSelector(packingSelector);

  const [tableData, setTableData] = useState<any[]>(packages);

  const onAddHandler = () => {
    dispatch(postAddNewPackingRequest());
    dispatch(getCountryListRequest());
    openModal();
  };

  useEffect(() => {
    dispatch(getAllPackingsRequest());
  }, []);

  useEffect(() => {
    setTableData(packages);
  }, [packages]);

  useEffect(() => {
    // @ts-expect-error
    if (Object.keys(getPackingErrorResponses).length !== 0 && getPackingErrorResponses?.status === 403) {
      navigate('/error');
    }
  }, [getPackingErrorResponses]);

  return (
    <div className={styles.wrapper}>
      {Object.keys(getPackingErrorResponses).length !== 0 ? <ErrorResponse error={getPackingErrorResponses} /> : null}
      {Object.keys(addPackingErrorResponses).length !== 0 ? <ErrorResponse error={addPackingErrorResponses} /> : null}
      {Object.keys(updatePackingErrorResponses).length !== 0 ? (
        <ErrorResponse error={updatePackingErrorResponses} />
      ) : null}
      {Object.keys(getPackingByIdErrorResponses).length !== 0 ? (
        <ErrorResponse error={getPackingByIdErrorResponses} />
      ) : null}

      <div className={styles.loadingGroup}>
        <span className={styles.packagingSubTitle}>Пакування</span>
        {loading ? <Spinner /> : null}
      </div>
      <span>Створюйте і редагуйте типи ящиків (Level 2)</span>
      <section className={styles.header}>
        <div className={styles.searchSection}>
          <div className={styles.searchGroup}>
            <span>Фільтр по всім полям:</span>
            <InputSearch setTableData={setTableData} data={packages} />
          </div>
          <Button variant="contained" className={styles.btn} onClick={onAddHandler}>
            <span className={styles.btnText}>Додати</span>
            <AddIcon className={styles.icon} />
          </Button>
        </div>
      </section>
      <section>
        <DataTable data={tableData} />
      </section>
      <AddPackModal isShown={isShow} closeModal={closeModal} />
    </div>
  );
};
