import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { TextField, Modal, Typography, Box, Button, MenuItem, Tooltip } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SimpleField from '../SimpleField';
import SelectField from '../SelectField';
import { ImageViewerModal } from '../ImageViewerModal/ImageViewerModal';
import useModal from '../../shared/hooks/useModal/useModal';

import styles from './EditTemplateModal.module.scss';

import SaveIcon from '@mui/icons-material/Save';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearPhotoUnit,
  deleteUnitTemplatesByIdRequest,
  getPhotoUnitRequest,
  putUpdateUnitTemplatesRequest,
  putUpdateUnitTemplatesSuccess,
} from '../../Templates/UnitTemplates/services/actions';
import {
  clearPhotoPallet,
  deletePalletTemplatesByIdRequest,
  getPhotoPalletRequest,
  putUpdatePalletTemplatesRequest,
  putUpdatePalletTemplatesSuccess,
} from '../../Templates/PalletTemplates/services/actions';
import {
  clearPhotoBox,
  deleteBoxTemplatesByIdRequest,
  getPhotoBoxRequest,
  putUpdateBoxTemplatesRequest,
  putUpdateBoxTemplatesSuccess,
} from '../../Templates/BoxTemplates/services/actions';
import { palletTemplateSelector } from '../../Templates/PalletTemplates/services/selector';
import { boxTemplateSelector } from '../../Templates/BoxTemplates/services/selector';
import { unitTemplateSelector } from '../../Templates/UnitTemplates/services/selector';
import DeleteEntryModal from '../DeleteEntryModal';
import { format } from 'date-fns';

import { countryListSelector } from '../../../entities/Countries/selector';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
};

const textFieldStyle = {
  bgcolor: '#FFE5B4',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#B0BEC5',
    },
    '&:hover fieldset': {
      borderColor: '#B0BEC5',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#B0BEC5',
    },
  },
};

interface Props {
  isShown: boolean;
  level: number;
  data: any;
  closeModal: () => void;
}

export const EditTemplateModal: React.FC<Props> = ({ isShown, closeModal, level, data }) => {
  const dispatch = useDispatch();
  const { isShow: isShownViewer, openModal: openViewerModal, closeModal: closeViewerModal } = useModal();
  const { isShow: isShownDelete, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();

  const { updatePalletResponse, photoPallet } = useSelector(palletTemplateSelector);
  const { updateBoxResponse, photoBox } = useSelector(boxTemplateSelector);
  const { updateUnitResponse, photoUnit } = useSelector(unitTemplateSelector);

  const { t } = useTranslation();

  const onOpenModal = () => {
    openViewerModal();

    if (data?.photos.length > 0) {
      if (level === 0) {
        dispatch(getPhotoUnitRequest(data?.photos[0]?.url));
      }
      if (level === 2) {
        dispatch(getPhotoBoxRequest(data?.photos[0]?.url));
      }
      if (level === 3) {
        dispatch(getPhotoPalletRequest(data?.photos[0]?.url));
      }
    }
  };

  const onCloseModal = () => {
    dispatch(clearPhotoUnit());
    dispatch(clearPhotoBox());
    dispatch(clearPhotoPallet());
    closeViewerModal();
    closeDeleteModal();
  };

  const onDeleteHandler = (values: any) => {
    if (level === 0) {
      dispatch(deleteUnitTemplatesByIdRequest({ labelTemplateId: values.id }));
    }
    if (level === 3) {
      dispatch(deletePalletTemplatesByIdRequest({ labelTemplateId: values.id }));
    }
    if (level === 2) {
      dispatch(deleteBoxTemplatesByIdRequest({ labelTemplateId: values.id }));
    }
    closeModal();
    closeDeleteModal();
  };

  const onSaveHandler = (values: any) => {
    if (level === 0) {
      dispatch(putUpdateUnitTemplatesRequest({ ...values, templateName: values?.templateName.trim(), level }));
    }
    if (level === 3) {
      dispatch(putUpdatePalletTemplatesRequest({ ...values, templateName: values?.templateName.trim(), level }));
    }
    if (level === 2) {
      dispatch(putUpdateBoxTemplatesRequest({ ...values, templateName: values?.templateName.trim(), level }));
    }
  };

  const onCloseHandler = () => {
    dispatch(putUpdatePalletTemplatesSuccess({}));
    dispatch(putUpdateUnitTemplatesSuccess({}));
    dispatch(putUpdateBoxTemplatesSuccess({}));
    closeModal();
  };

  const { countries } = useSelector(countryListSelector);

  useEffect(() => {
    if (Object.keys(updatePalletResponse).length !== 0 && updatePalletResponse?.messages?.Successful !== null) {
      closeModal();
    }
  }, [updatePalletResponse]);
  useEffect(() => {
    if (Object.keys(updateBoxResponse).length !== 0 && updateBoxResponse?.messages?.Successful !== null) {
      closeModal();
    }
  }, [updateBoxResponse]);
  useEffect(() => {
    if (Object.keys(updateUnitResponse).length !== 0 && updateUnitResponse?.messages?.Successful !== null) {
      closeModal();
    }
  }, [updateUnitResponse]);

  return (
    <div>
      {Object.keys(data).length !== 0 ? (
        <>
          <Modal
            open={isShown}
            onClose={() => {
              closeModal();
              dispatch(putUpdatePalletTemplatesSuccess({}));
              dispatch(putUpdateUnitTemplatesSuccess({}));
              dispatch(putUpdateBoxTemplatesSuccess({}));
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className={styles.menu}>
                {level === 2 ? (
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Редагування шаблону агрегаційної етикетки ящика
                    <Tooltip
                      title={
                        'Список доступних змінних: $ean$ - значення GTIN; $qty$ - кількість одиниць в агрегаті; $series$ - значення серії; $expDateDD$ - значення DD Спожити до упаковка; $expDateMM$ - значення MM Спожити до упаковка; $expDateYY$  - значення YY Спожити до упаковка; $expDateYYYY$  - значення YYYY Спожити до упаковка; $ProductNameExternal$ - експортна назва продукції даної країни; $country$ - код країни; $sscc$ - значення SSCC; $userName$ - пакувальник;   $mfgDD$ - день дати виробництва з рецепту; $mfgMM$ - місяць дати виробництва з рецепту; $mfgYY$ і $mfgYYYY$ - рік дати виробництва з рецепту;'
                      }
                      placement="right"
                    >
                      <HelpOutlineIcon className={styles.helper} />
                    </Tooltip>
                  </Typography>
                ) : null}
                {level === 3 ? (
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Редагування шаблону етикетки паллети
                    <Tooltip
                      title={
                        'Список доступних змінних: $boxamount$ - кількість ящиків в агрегаті; $type$ - тип палети; $brutt$ - вага БРУТТО, кг; $PackagingDate$ - дата пакування; $country$ - код країни; $sscc$ - значення SSCC; $purchaseOrder$ - номер замовлення чи інвойсу.'
                      }
                      placement="right"
                    >
                      <HelpOutlineIcon className={styles.helper} />
                    </Tooltip>
                  </Typography>
                ) : null}
                {level === 0 ? (
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Редагування шаблону одиниці продукції
                    <Tooltip
                      title={
                        'Список доступних змінних:\n$gtin$	- значення GTIN;\n$serialNumber$ - значення серійного номера;\n$mrp$	- значення МРЦ;\n$expDateDD$ - значення DD Спожити до упаковка;\n$expDateMM$ - значення MM Спожити до упаковка;\n$expDateYY$ - значення YY Спожити до упаковка;\n$expDateYYYY$ - значення YYYY Спожити до упаковка;\n$mfg$ - значення Дата виробництва (MMYYYY);\n$series$ - значення серії;\n$ai91$ - значення блоку 91;\n$ai92$ - значення блоку 92;\n$ai17$ - значення дати Спожити до в форматі YYMMDD; $mfgDD$ - день дати виробництва з рецепту; $mfgMM$ - місяць дати виробництва з рецепту; $mfgYY$ і $mfgYYYY$ - рік дати виробництва з рецепту;'
                      }
                      placement="right"
                    >
                      <HelpOutlineIcon className={styles.helper} />
                    </Tooltip>
                  </Typography>
                ) : null}
                <div>
                  <DeleteOutlinedIcon className={styles.icon} onClick={openDeleteModal} />
                  <CloseIcon className={styles.icon} onClick={onCloseHandler} />
                </div>
              </div>
              <div className={styles.editor}>
                <span className={styles.editorLegend}>
                  Створено: {data?.creatorEmail},{' '}
                  {data.createDate && data.createDate !== null
                    ? format(new Date(data?.createDate), 'yyyy-MM-dd HH:mm:ss')
                    : null}
                  {', '}
                  востаннє оновленно:{' '}
                  {data.updateDate && data.updateDate !== null
                    ? format(new Date(data?.updateDate), 'yyyy-MM-dd HH:mm:ss')
                    : null}
                </span>
              </div>
              <Formik initialValues={data} onSubmit={() => undefined}>
                {({ values, handleSubmit, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={styles.header}>
                      <div className={styles.wrapper}>
                        <span className={styles.title}>
                          Найменування шаблону (ID {values.id}) <em className={styles.required}>*</em>
                        </span>
                        <SimpleField
                          name="templateName"
                          hasMarginTop={false}
                          value={values.templateName}
                          validate={true}
                        />
                      </div>

                      <div className={styles.wrapper}>
                        <span className={styles.title}>
                          {t('printLang')} <em className={styles.required}>*</em>
                        </span>
                        <SelectField
                          title=""
                          name="lang"
                          value={values.lang}
                          variant="noLabel"
                          size="small"
                          error={values.lang === null}
                        >
                          <MenuItem value="ZPL" sx={{ fontSize: '13px' }}>
                            ZPL
                          </MenuItem>
                          <MenuItem value="DPL" sx={{ fontSize: '13px' }}>
                            DPL
                          </MenuItem>
                          <MenuItem value="XML" sx={{ fontSize: '13px' }}>
                            XML
                          </MenuItem>
                          <MenuItem value="DP" sx={{ fontSize: '13px' }}>
                            DP
                          </MenuItem>
                          {level === 0 ? (
                            <MenuItem value="REA" sx={{ fontSize: '13px' }}>
                              REA
                            </MenuItem>
                          ) : null}
                          {level === 0 ? (
                            <MenuItem value="WOLKE" sx={{ fontSize: '13px' }}>
                              WOLKE
                            </MenuItem>
                          ) : null}
                        </SelectField>
                      </div>
                      {level !== 2 ? (
                        <div className={styles.wrapper}>
                          <span className={styles.title}>
                            Країна <em className={styles.required}>*</em>
                          </span>
                          <SelectField
                            title={''}
                            variant="noLabel"
                            name="countryCodeId"
                            size="small"
                            value={values.countryCodeId}
                            dropdownValues={countries}
                            error={values.countryCodeId === null}
                          >
                            {countries.map((country: any) => (
                              <MenuItem value={country.id} sx={{ fontSize: '13px' }}>
                                {country.code}, {country.countryName}
                              </MenuItem>
                            ))}
                          </SelectField>
                        </div>
                      ) : null}
                    </div>
                    <div className={styles.iconViewer}>
                      <span className={styles.title}>Фото шаблону:</span>
                      <Tooltip title="Змінити фото" placement="right">
                        <AddPhotoAlternateIcon onClick={onOpenModal} className={styles.icon} />
                      </Tooltip>
                    </div>
                    <div className={styles.body}>
                      <span className={styles.title}>Код шаблону:</span>
                      <TextField
                        name="value"
                        id="outlined-multiline-static"
                        label=""
                        inputProps={{ style: { fontSize: 14, fontFamily: 'monospace', lineHeight: 1 } }}
                        sx={textFieldStyle}
                        onChange={(e) => setFieldValue('value', e.target.value)}
                        multiline
                        rows={30}
                        value={values.value}
                      />
                    </div>
                    {level === 0 ? (
                      <div className={styles.wrapper}>
                        <span className={styles.title}>Коментар майстру:</span>
                        <SimpleField
                          name="comment"
                          maxLength={60}
                          hasMarginTop={false}
                          value={values.comment}
                          validate={false}
                        />
                      </div>
                    ) : null}
                    <section className={styles.btnSection}>
                      <>
                        {Object.keys(updateUnitResponse).length !== 0 &&
                        updateUnitResponse?.messages?.Failed !== null ? (
                          <span className={styles.errorMessage}>{updateUnitResponse?.messages?.Failed[0]}</span>
                        ) : Object.keys(updatePalletResponse).length !== 0 &&
                          updatePalletResponse?.messages?.Failed !== null ? (
                          <span className={styles.errorMessage}>{updatePalletResponse?.messages?.Failed[0]}</span>
                        ) : (
                          <span className={styles.errorMessage}>{updateBoxResponse?.messages?.Failed[0]}</span>
                        )}
                      </>
                      <div>
                        <Button
                          className={styles.saveBtn}
                          variant="contained"
                          disabled={
                            values.value === null ||
                            values.templateName === null ||
                            values.templateName.trim().length === 0
                          }
                          onClick={() => onSaveHandler(values)}
                        >
                          <span className={styles.btnText}>Зберегти</span>
                          <SaveIcon className={styles.icon} />
                        </Button>
                      </div>
                    </section>
                    <DeleteEntryModal
                      title={`Видалити шаблон?`}
                      isShow={isShownDelete}
                      closeModal={closeDeleteModal}
                      deleteHandler={() => onDeleteHandler(values)}
                    />
                  </form>
                )}
              </Formik>
            </Box>
          </Modal>
          <ImageViewerModal
            isShownViewer={isShownViewer}
            closeViewerModal={onCloseModal}
            extended={true}
            labelTemplateId={data?.id}
            img={level === 0 ? photoUnit : level === 2 ? photoBox : photoPallet}
            level={level}
          />
        </>
      ) : null}
    </div>
  );
};
