import MenuIcon from '@mui/icons-material/Menu';
import { DataGrid, GridRenderCellParams, ukUA } from '@mui/x-data-grid';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPackingByIdRequest } from '../../Handbook/Packaging/services/actions';
import { packingSelector } from '../../Handbook/Packaging/services/selector';
import useModal from '../../shared/hooks/useModal/useModal';
import { EditPackModal } from '../EditPackModal/EditPackModal';
import { getCountryListRequest } from '../../../entities/Countries/actions';
import { style } from './style';

import styles from './DataTable.module.scss';

interface Props {
  data: any;
}

export const DataTable: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();
  const { isShow, openModal, closeModal } = useModal();
  const [pageSize, setPageSize] = React.useState(16);

  const onEditHandler = (id: number) => {
    dispatch(getPackingByIdRequest({ packingId: id }));
    dispatch(getCountryListRequest());
    openModal();
  };

  const { packingById } = useSelector(packingSelector);

  return (
    <div style={{ height: 658, width: '100%', marginTop: '21px' }}>
      <DataGrid
        localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
        rows={data}
        headerHeight={43}
        rowHeight={35}
        disableSelectionOnClick
        columns={[
          { field: 'id', headerName: 'ID', width: 70 },
          { field: 'name', headerName: 'Найменування пакування', width: 300 },
          { field: 'countryCode', headerName: 'Країна', width: 85 },
          { field: 'weight', headerName: 'Вага пакування, гр', width: 150 },
          { field: 'usedIn', headerName: 'Пов`язані об`єкти', minWidth: 450, flex: 0.9 },
          {
            field: 'button',
            headerName: 'Меню',
            headerAlign: 'center',
            width: 60,
            align: 'center',
            renderCell: (params: GridRenderCellParams<Date>) => (
              <MenuIcon className={styles.icon} onClick={() => onEditHandler(params.row.id)} />
            ),
          },
        ]}
        sx={style}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        disableColumnMenu
        componentsProps={{
          pagination: {
            SelectProps: {
              MenuProps: {
                sx: {
                  '& .MuiMenuItem-root': {
                    fontSize: 14,
                    bgcolor: '#ffffff',
                  },
                },
                PaperProps: {
                  sx: {
                    bgcolor: '#ffffff',
                  },
                },
              },
            },
          },
        }}
      />
      <EditPackModal isShown={isShow} closeModal={closeModal} data={packingById} />
    </div>
  );
};
