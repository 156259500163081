import { Box, Button, Modal } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { ModalSpinner } from '../../ModalSpinner/ModalSpinner';
import { postUnreserveMarkingCodesSeriesRequest } from '../../../EventsLog/SeriesReport/services/actions';
import { seriesReportSelector } from '../../../EventsLog/SeriesReport/services/selector';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 510,
  height: 240,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
};

interface IReprintModalProps {
  isShown: boolean;
  innerModalLoading: boolean;
  closeModal: () => void;
  params: any;
}

export const ReprintInnerModal = ({ isShown, innerModalLoading, closeModal, params }: IReprintModalProps) => {
  const dispatch = useDispatch();

  const { postUnreserveMarkingCodes } = useSelector(seriesReportSelector);

  useEffect(() => {
    if (postUnreserveMarkingCodes?.length > 0) {
      closeModal();
    }
  }, [postUnreserveMarkingCodes]);

  return (
    <Modal
      open={isShown}
      onClose={() => {
        closeModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.innerModalSpinnerWrapper}>{innerModalLoading && <ModalSpinner />}</div>

        <span className={styles.title}>Виконуйте із застереженням нижче!</span>
        <div className={styles.text}>
          <span>
            Оперерація пересе в Банк кодів і дозволить повторний друк всіх неагрегованих упаковок даної серії, які не
            агреговано або не вилучено. Для запобіганя дублів агрегуйте всі упаковки в тимчасові ящики, які пізніше
            деагрегуєте.
          </span>
        </div>

        <div className={styles.btnGroupInner}>
          <Button
            className={styles.submitBtn}
            disabled={innerModalLoading}
            variant="contained"
            onClick={() => {
              dispatch(postUnreserveMarkingCodesSeriesRequest(params));
              closeModal();
            }}
          >
            <span className={styles.btnText}>Виконати</span>
          </Button>
          <Button className={styles.exitBtn} variant="contained" onClick={closeModal}>
            <span className={styles.btnText}>Скасувати</span>
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
