import { Box, Button, Modal } from '@mui/material';
import React, { useEffect } from 'react';

import styles from './PrintCodesInfoModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { postPrintJobDetailedRequest, postUnreserveMarkingCodesRequest } from '../services/actions';
import { printCodesSelector } from '../services/selector';
import { ModalSpinner } from '../../../common/ModalSpinner/ModalSpinner';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 510,
  height: 240,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
  fontSize: '13px !important',
};

interface Props {
  isShown: boolean;
  params: any;
  closeModal: () => void;
}

export const PrintCodeInnerModal: React.FC<Props> = ({ isShown, params, closeModal }) => {
  const dispatch = useDispatch();
  const { postUnreserveMarkingCodes, innerModalLoading } = useSelector(printCodesSelector);

  useEffect(() => {
    if (postUnreserveMarkingCodes?.length > 0) {
      closeModal();
      dispatch(postPrintJobDetailedRequest(params));
    }
  }, [postUnreserveMarkingCodes]);

  return (
    <Modal
      open={isShown}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.innerModalSpinnerWrapper}>{innerModalLoading ? <ModalSpinner /> : null}</div>
        <span className={styles.title}>Виконуйте із застереженням нижче!</span>
        <div className={styles.text}>
          <span>
            Оперерація пересе в Банк кодів і дозволить повторний друк всіх неагрегованих упаковок даного завдання, які
            не агреговано або не вилучено. Для запобіганя дублів агрегуйте всі упаковки в тимчасові ящики, які пізніше
            деагрегуєте.
          </span>
        </div>

        <div className={styles.btnGroupInner}>
          <Button
            className={styles.submitBtn}
            disabled={innerModalLoading}
            variant="contained"
            onClick={() => {
              dispatch(postUnreserveMarkingCodesRequest(params));
              closeModal();
            }}
          >
            <span className={styles.btnText}>Виконати</span>
          </Button>
          <Button className={styles.exitBtn} variant="contained" onClick={closeModal}>
            <span className={styles.btnText}>Скасувати</span>
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
